<template>
    <div>
      <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
      <div v-else>
        <div class="man-title">
          <!-- <span>告警规则</span> -->
          <el-button @click="add" type="primary" size="mini" :disabled="roleId == 4">新增定时通知</el-button>
        </div>
        <div class="table-list">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="title" label="通知名称"></el-table-column>
            <el-table-column prop="content" label="通知内容"></el-table-column>
            <el-table-column prop="noticeTypeCode" label="通知类型">
              <template slot-scope="scope">
                <span v-show="scope.row.noticeTypeCode=='equipment'">设备类型</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="level" label="告警级别">
              <template slot-scope="scope">
                <el-tag size="small" v-show="scope.row.level==1">提示告警</el-tag>
                <el-tag type="info" size="small" v-show="scope.row.level==2">普通告警</el-tag>
                <el-tag type="warning" size="small" v-show="scope.row.level==3">重要告警</el-tag>
                <el-tag type="danger" size="small" v-show="scope.row.level==4">严重告警</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column prop="creator" label="创建人"></el-table-column>
            <el-table-column prop="createTime" label="更新时间">
              <template slot-scope="scope">
                {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-dropdown v-if="staffId == scope.row.creatorId" placement="bottom-start" trigger="click">
                  <span @click.stop="">
                    <span class="el-dropdown-link">
                      操作<i class="el-icon-arrow-down"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                    <el-dropdown-item :disabled="roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown v-else placement="bottom-start" trigger="click">
                  <span @click.stop="">
                    <span class="el-dropdown-dis">
                      操作<i class="el-icon-arrow-down"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown"></el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <!-- <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page> -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import page from '@/components/page.vue'
  import dayjs from 'dayjs';
  export default {
    components: {
      page
    },
    data() {
      return {
        loading: false,
        size: 50,
        page: 1,
        total: 0,
        tableData: [],
        staffId: 0,
        roleId: 0
      }
    },
    methods: {
      dayjs,
      onOff(row) {
        this.$ajax
          .post("ruleStatus", {
            id: row.id,
            status: row.status == 0 ? 1 : 0
          })
          .then((res) => {
            this.loadTableData();
          });
      },
      details(row) {
        this.$router.push({ name: 'customNoticeDetail', params: {row: row}})
      },
      edit(row) {
        this.$router.push({ name: 'customNoticeEdit', params: {row: row}})
      },
      dlt(row) {
        console.log(row.id)
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$ajax.post("timeNoticeDelete",{},{}, row.timerNoticeRuleId).then((res) => {
            this.loadTableData();
          });
        });
      },
      add() {
        this.$router.push('/receive/custom/add')
      },
      loadTableData() {
        this.loading = true
        this.$ajax.post('timeNoticeQuery').then(res => {
          this.tableData = res.data
          this.total = res.total
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      },
      pageChange(val) {
        this.page = val
        sessionStorage.setItem('currentPage', val)
        this.loadTableData()
      },
      sizeChange(val) {
        this.size = val
        sessionStorage.setItem('currentSize', val)
        this.loadTableData()
      },
      getLastPage() {
        this.page = Number(sessionStorage.getItem('currentPage')) || 1
      },
      getSize() {
        this.size = Number(sessionStorage.getItem('currentSize')) || 50
      },
    },
    mounted() { 
      this.getLastPage()
      this.getSize()
      this.loadTableData()
      this.staffId = sessionStorage.getItem('id')
      this.roleId = sessionStorage.getItem('r')
    },
    destroyed() {
      
    }
  }
  </script>
  
  <style lang="less" scoped>
  .mains {
    padding: 375px 0;
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .el-dropdown-dis {
    color: #ccc;
    cursor: not-allowed;
  }
  .btn {
    float: right;
  }
  </style>