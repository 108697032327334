<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <!-- <span>消息通知</span> -->
        <div>
            <el-button @click="allRead" size="mini">全部已读</el-button>
            <el-button @click="del" size="mini">删除</el-button>
            <el-button @click="add" type="primary" size="mini">新增标准通知</el-button>
        </div>
      </div>

      <div class="table-list">
        <el-table :data="receiveData" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="标题">
            <template slot-scope="scope">
              <span class="title-name" @click="details(scope.row)">{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="notificationType" label="通知类型">
            <template slot-scope="scope">
              {{ scope.row.notificationType==1 ? '系统通知' : '企业通知' }}
            </template>
          </el-table-column>
          <el-table-column prop="creator" label="发布者"></el-table-column>
          <el-table-column prop="createTime" label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
      <!-- 选择人员 -->
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">选择接收人员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                node-key="id"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                highlight-current
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <!-- {{staffListID.includes(scope.row.id)}} -->
                    <!-- v-model="scope.row.check" -->
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button type="primary" size="small" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <!-- 新增通知 -->
      <el-drawer
        title="新增通知"
        :with-header="false"
        :visible.sync="createDrawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">新增通知</div>
        <el-form style="padding: 20px;" :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="通知标题：" prop="noticeTitle">
            <el-input v-model="form.noticeTitle" type="text" placeholder="请输入通知标题" maxlength="30" show-word-limit/>
          </el-form-item>
          <el-form-item label="通知内容：" prop="noticeContent">
            <el-input v-model="form.noticeContent" type="textarea" placeholder="请输入通知内容" maxlength="200" show-word-limit rows="10"/>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="接收人员：">
                <div @click="addStaff()" class="staffBox">
                  <div v-if="staffList.length>0">
                    <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                      {{ staff.name }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">请选择接收人员</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="通知方式：" prop="noticeMode">
            <div class="notify-method">
              <el-checkbox-group v-model="form.noticeMode">
                <el-checkbox label="system">系统</el-checkbox>
                <el-checkbox label="sms">短信</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </el-form>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="createDrawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="createDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <!-- 通知详情 -->
      <el-drawer
        title="通知详情"
        :with-header="false"
        :visible.sync="detailDrawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">通知详情</div>
        <el-form style="padding: 20px;" :model="detailForm" ref="ruleForm" label-width="100px">
          <el-form-item label="通知标题：">
            <el-input v-model="detailForm.title" type="text" disabled/>
          </el-form-item>
          <el-form-item label="发布者：">
            <el-input v-model="detailForm.creator" type="text" value="系统" disabled/>
          </el-form-item>
          <el-form-item label="发布时间：">
            <el-input v-model="detailForm.createTime" type="text" value="2011-12-01 12:00:00" disabled/>
          </el-form-item>
          <el-form-item label="通知内容：">
            <el-input v-model="detailForm.content" type="textarea" value="通知内容" disabled rows="10"/>
          </el-form-item>
        </el-form>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="detailDrawer=false">取消</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      noticeNum: 9,
      activeName: 'first',
      alarmCount: null,
      dailyReportCount: null,
      noticeCount: null,
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      receiveData: [],
      isChangeOpen: 0,
      isDetailOpen: 0,
      isEditOpen: 0,
      sheshi: [],
      bumen: [],
      leixin: [
        { id: 1, name: '系统通知' },
        { id: 2, name: '企业通知' },
      ],
      yuangong: [],
      detailRow: {},
      editRow: {},
      roleId: 0,
      deleteNotice: [],// 删除数据项的数组

      drawer: false,//选择接收人员
      form: {
        noticeTitle: '',
        noticeContent: '',
        noticeMode: ['system']
      },
      rules: {
        noticeTitle:[{ required: true, message: '必填项', trigger: 'blur' }],
        noticeContent:[{ required: true, message: '必填项', trigger: 'blur' }],
        noticeMode: [{ required: true, message: "必填项", trigger: "change" }]        
      },
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      checkedStaffList: [],
      staffListID: [],
      staffList: [],

      createDrawer: false,// 新增通知
      detailDrawer: false,// 通知详情
      detailForm: {}
    };
  },
  methods: {
    dayjs,
    
    // Tabs 切换
    handleClick(e) {
      this.activeName = e.name
      if(e.name=='first'){
        // this.loadList()
      }else if(e.name=='second') {
        // 已读日报
        this.reportHandleAll()
      }else if(e.name=='third') {
        // 已读消息
        this.noticeReadAll()
      }
    },

    // 已读日报
    reportHandleAll() {
      if(this.dailyReportCount>0) {
        this.loading = true
        this.$ajax.post('reportHandleAll').then(res => {
          this.$message.success('日报通知已读')
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      }
      this.$forceUpdate()
    },

    // 已读消息
    noticeReadAll() {
      if(this.noticeCount>0) {
        this.loading = true
        this.$ajax.post('noticeReadAll').then(res => {
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      }
      this.$forceUpdate()
    },

    // 选择要删除的表格数据
    handleSelectionChange(val) {
      this.deleteNotice = val;
    },

    // 全部已读
    allRead() {
      // 调用全部已读接口
      this.$confirm('确定要标记全部消息为已读吗?', '全部已读', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // if(this.activeName=='first'){
        // }else if(this.activeName=='second') {
        //   this.reportHandleAll()
        // }else if(this.activeName=='third') {
        this.noticeReadAll()
        // }
      })
    },

    // 删除数据
    del() {
      if(this.deleteNotice.length==0) {
        this.$message.error("请选择需要删除的数据项");
        return
      }
      let noticeIds = []
      this.deleteNotice.forEach(v=>noticeIds.push(v.id))
      // 调用删除接口
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('noticeDelete', {
          notificationIds: noticeIds
        }).then(res => {
          this.loadListData()
        })
      })
    },

    // 新增通知
    add() {
      this.createDrawer = true
    },

    // 通知详情
    details(row) {
      row.createTime = dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss')
      this.detailForm = row
      this.detailDrawer = true
    },

    // 查询通知
    onSubmit() {
      this.loadListData()
    },

    // 获取列表数据
    loadListData() {
      this.loading = true
      this.$ajax.post('noticeReceiverQuery', {
        pageNum: this.page,
        pageSize: this.size,
        // notificationType: this.formInline.notificationType ? this.formInline.notificationType : null
      }).then(res => {
        this.receiveData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },

    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },

    // 选择巡检员
    addStaff() {
      this.loadTableData(this.treeData[0].id)
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)
      this.drawer = true
    },

    // 获取部门结构树
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
            console.log(this.staffListID)
          }
        })
      }
    },
    // 选择巡检员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },

    // 新增通知点确定请求接口
    createDrawerOK() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.staffList==0) {
            this.$message.error('请选择接收人员')
            return
          }
          this.loading = true
          this.$ajax.post('noticeCreate', {
            notificationModeCodes: this.form.noticeMode,
            content: this.form.noticeContent,
            // notificationType: 2,
            staffIds: this.staffListID,
            title: this.form.noticeTitle
          }).then(res => {
            // 提交成功后清除基础数据  
            this.form.noticeTitle = ''
            this.form.noticeContent = ''
            this.form.noticeMode = ['system']
            this.checkedStaffList = []
            this.staffListID = []
            this.staffList = []
            this.loading = false
            this.createDrawer = false
            this.loadListData()
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    // 获取所有消息数量
    getNoticeCount() {
      this.$ajax.post('latestCountQuery').then(res => {
        this.alarmCount = res.data.alarmCount
        this.dailyReportCount = res.data.dailyReportCount
        this.noticeCount = res.data.noticeCount
      })
    }
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadListData()
    this.loadTreeData()
    this.getNoticeCount()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 375px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
.title-name {
  color: #409EFF;
  cursor: pointer;
  text-decoration: underline;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 640px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #DCDFE6;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>