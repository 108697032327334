<template>
  <div>
    <div class="man-title">
      <span>消息通知</span>
      <div class="btn">
        <el-radio-group v-model="dateSelect" size="small" @change="dateChange">
          <el-radio-button label="standard">标准通知</el-radio-button>
          <el-radio-button label="atRegularTime">定时通知</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <standardNotice v-if="dateSelect=='standard'"></standardNotice>
    <customNotice v-if="dateSelect=='atRegularTime'"></customNotice>
  </div>
</template>

<script>
import standardNotice from './standardNotice/index.vue'
import customNotice from './customNotice/index.vue'
export default {
  components: {
    standardNotice,
    customNotice
  },
  data() {
    return {
      dateSelect: 'standard'
    };
  },
  methods: {
    dateChange(e) {
      sessionStorage.setItem('noticeType',e)
    }
  },
  mounted() {
    if(sessionStorage.getItem('noticeType')) {
      this.dateSelect = sessionStorage.getItem('noticeType')
    }
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
.btn {
  float: right;
}
/deep/.el-radio-group {
  background-color: #F5F5F5;
  padding: 5px 0;
  border-radius: 4px;
}
/deep/.el-radio-button__inner {
  border: 0;
  background: none;
  border-radius: 4px;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  transition: none;
  user-select: none;
}
/deep/.el-radio-button__inner:hover {
  background: #E7E7E7;
  color: #409EFF;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner:hover {
  background: #409EFF;
  color: #FFF;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border: 0;
  border-radius: 4px;
}
/deep/.el-radio-button:last-child .el-radio-button__inner {
  border: 0;
  border-radius: 4px;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>